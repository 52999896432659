<template>

        <div class="h-full w-full rounded-lg flex flex-col relative bg-filters" @click="goto('Chargeability')">

            <loader :loading="loading" />

            <div class="h-8 p-3 flex flex-row justify-between items-center">
                <span class="font-semibold text-xs" style="color: #13F2E3">Chargeability</span>
            </div>

            <div class="flex-1 min-h-0 flex flex-col p-1">

                <div class="flex-1 min-h-0 flex flex-row justify-center items-center py-2">
                    <div class="h-full w-full flex flex-col justify-center items-center">
                        <span class="text-xxs font-semibold">YTD</span>
                        <span class="text-white font-semibold text-3xs">{{ chargeability.ytd | reduceBigNumbers(2) }} %</span>
                    </div>
                    <div class="h-full w-full flex flex-col justify-center items-center">
                        <span class="text-xxs text-font-gray font-semibold">YTD-1</span>
                        <span class="text-font-gray font-semibold text-3xs">{{ chargeability.ytd_1 | reduceBigNumbers(2) }} %</span>
                    </div>
                    <div class="h-full w-full flex flex-col justify-center items-center">
                        <span class="text-xxs text-plan font-semibold">PLAN</span>
                        <span class="text-plan font-semibold text-3xs">{{ chargeability.plan | reduceBigNumbers(2) }} %</span>
                    </div>
                </div>

                <div class="flex flex-col mt-auto">
                    <div class="h-1 w-11/12 mx-auto rounded-full mt-auto bg-module-top overflow-hidden relative">
                        <div class="h-full absolute rounded-full bg-white top-0 left-0" :style="'width:' + chargeability.ytd + '%; transition: all .3s;'"></div>
                    </div>
                    <div class="h-1 w-11/12 mx-auto rounded-full mt-2 bg-module-top overflow-hidden relative">
                        <div class="h-full absolute rounded-full bg-font-gray top-0 left-0" :style="'width:' + chargeability.ytd_1  + '%; transition: all .3s;'"></div>
                    </div>
                    <div class="h-1 w-11/12 mx-auto rounded-full mt-2 mb-2 bg-module-top overflow-hidden relative">
                        <div class="h-full absolute rounded-full bg-plan top-0 left-0" :style="'width:' + chargeability.plan  + '%; transition: all .3s;'"></div>
                    </div>
                </div>

            </div>

        </div>

</template>

<script>

    const ChartDoubleGauge = () => import('@/charts/ChartDoubleGauge.vue');
    import { state } from '@/store';

    export default {
        name: "ChargeabilityHomeMobile",
        components: {
            ChartDoubleGauge
        },
        data() {
            return {
                loading: false,
                chargeability: {}
            }
        },
        computed: {
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
                }
                return params
            },
        },
        watch: {
            params() { this.load() }
        },
        methods: {
            load() {
                this.loading = true;
                if(this.params) {
                    this.axios.get('chargeability/ytd', {params: this.params}).then(response => {
                        this.chargeability = response.data.data
                        this.loading = false
                    });
                }
            },
            goto(route) { this.$router.push( { name: route } )}
        },
        mounted(){
            this.load();
        }
    }
</script>>